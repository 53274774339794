
/* SAPI log on left nav */
.left-nav-sapi-logo {
  height: 3.5rem;
}

/* left navbar */
.left-nav-item .btn {
  width: 100%!important;
}


.left-nav-item .btn span {  /* Make left in title */
  float: left;
}
.left-nav-item .btn span svg { /* style icon in the title */
  margin-right: 10px;
  color: #FAB162;
}
.dropdown-menu.show {
  background: #E3E4E1;
}


.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: static!important; /* Remove overflow */
}
.dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 16px;
}
/* end left navbar */

/* navbar */
.sidebar {
    height: 100vh;
    /* background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .9)), url(images/img1.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    background-color: #000000;
    box-shadow: 5px 7px 25px #999;
    overflow: scroll;
  }
  
  .bottom-border {
    border-bottom: 1px groove #eee;
  }
  
  .sidebar-link {
    transition: all .4s;
  }
  
  .sidebar-link:hover {
    background-color: #444;
    border-radius: 5px;
  }
  
  .current {
    background-color: #f44336;
    border-radius: 7px;
    box-shadow: 2px 5px 10px #111;
    transition: all .3s;
  }
  
  .current:hover {
    background-color: #f66436;
    border-radius: 7px;
    box-shadow: 2px 5px 20px #111;
    transform: translateY(-1px);
  }
  
  .search-input {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #999;
    transition: all .4s;
  }
  
  .search-input:focus {
    background: transparent;
    box-shadow: none;
    border-bottom: 2px solid #dc3545;
  }
  
  .search-button {
    border-radius: 50%;
    padding: 10px 16px;
    transition: all .4s;
  }
  
  .search-button:hover {
    background-color: #eee;
    transform: translateY(-1px);
  }
  
  .icon-parent {
    position: relative;
  }
  
  .icon-bullet::after {
    content: "";
    position: absolute;
    top: 7px;
    left: 15px;
    height: 12px;
    width: 12px;
    background-color: #f44336;
    border-radius: 50%;
  }
  
  .notification-badge {
    position: absolute;
    top: 1px;
    background-color: #f44336;
    border-radius: 20%;
    color: white;
    font-size: 12px;
    top: 3px;
    left: 19px;
    text-align: center;
    height: 18px;
    width: 18px;
    font-weight: 800;
  }
  
  @media (max-width: 768px) {
    .sidebar {
      position: static;
      height: auto;
    }
  
    .top-navbar {
      position: static;
    }
  }
  /* end of navbar */




  /* Student sections */
  .pickup-address-container {
    height: 500px;
  }
  .dropoff-address-container  {
    height: 500px;
  }
  /* end of student sections */


  /* add student to route */
  .route-map-container {
    height: 500px;
  }
  /* end of add student to route */



  /* ReactJs DatePicker override css */

  /* .react-datepicker__time-container {
    width: 300px!important;
  } */

  .react-datepicker__input-container input {
    width: 225px;
  }
  .react-datepicker__time-box {
    width: 100%!important;
  }
  /* End of ReactJs DatePicker override css */



  /* Notifications Modal */
  .notification-body {
    height: 60vh;
    overflow-y: scroll;
  }

  
/* End of Notifications Modal */

