.login-bg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;    
    background: url("../assets/images/login_bg.jpeg") no-repeat;
    background-size: cover;
}

#login-bg-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

#login-container {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);    
}