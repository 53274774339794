  /* Chat */

  .chat-box-left {
    padding: 10px;
    background-color: antiquewhite;
    border-radius: 10px;
    
  }

  .chat-box-right {
    padding: 10px;
    background-color: burlywood;
    border-radius: 10px;
    
  }

  .chat-body {
    height: 60vh;
    overflow-y: scroll;
  }

  /* End of Chat */